import { useEffect, useState } from 'react';

import Head from 'next/head';
import Layout from '../components/layout';
import Blocks from '../components/blocks';
import { getHomePage } from '../lib/queries/getHomePage';
import type { Menu, TopMenu } from '../types/menu';
import type { ThemeSettings } from '../types/api';
import { GeneralSettings, PageBy } from '../types/api';
import { initAccount, loadAccount } from '../lib/account';
import { Account } from '../types/account';
import * as Sentry from '@sentry/nextjs';

interface PageData {
  pageBy: PageBy;
  header: {
    menu?: Menu;
    topmenu?: TopMenu;
    generalSettings?: GeneralSettings;
    themeSettings?: ThemeSettings;
  };
  footer: {
    footerColOneMenu: Menu | null;
    footerColTwoMenu: Menu | null;
    footerColThreeMenu: Menu | null;
    footerConnectMenu: Menu | null;
    themeSettings?: ThemeSettings;
  };
}

interface Props {
  pageData: PageData;
}

async function loadPage(): Promise<PageData> {
  const homepage = await getHomePage();
  return {
    pageBy: homepage?.pageBy,
    header: {
      generalSettings: homepage?.generalSettings,
      themeSettings: homepage?.themeSettings?.themeSettings || null,
      menu: homepage?.menu,
      topmenu: homepage?.topmenu,
    },
    footer: {
      footerColOneMenu: homepage?.footerColOneMenu || ({} as Menu),
      footerColTwoMenu: homepage?.footerColTwoMenu || ({} as Menu),
      footerColThreeMenu: homepage?.footerColThreeMenu || ({} as Menu),
      footerConnectMenu: homepage?.footerConnectMenu || ({} as Menu),
      themeSettings: homepage?.themeSettings?.themeSettings || null,
    }
  };
}

export default function Index({ pageData }: Props): JSX.Element {
  const [data, setData] = useState(pageData);
  const [account, setAccount] = useState<Account | undefined>(undefined);

  useEffect(() => {
    setAccount(initAccount());
    
    async function fetchData() {
      try {
        const data = await loadPage();
        if (data.pageBy) {
          setData(data);
        }

        const account = await loadAccount();
        setAccount(account);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Layout header={data.header} footer={data.footer} account={account}>
        <Head>
          <title>{data.pageBy?.seo?.title}</title>
          <meta name="description" content={data.pageBy?.seo?.metaDesc} />
        </Head>
        <section>
          <Blocks fullWidth={true} blocks={data.pageBy?.blocks} />
        </section>
      </Layout>
    </>
  );
}

export async function getStaticProps(): Promise<{
  props: Props;
  revalidate: number;
}> {
  const page = await loadPage();
  return {
    props: {
      pageData: page,
    },
    revalidate: 30,
  };
}
